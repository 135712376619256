<template>
  <v-container fluid class="mt-0 pt-0">
    <loader
      v-bind:isVisible="$store.getters.getGutachtenDetailOverlay"
    ></loader>
    <v-row class="pl-5">
      <v-col cols="auto" class="pl-0 pr-0 pt-0">
        <v-sheet rounded>
          <v-text-field
            class="pt-3 pl-3 pr-3 pb-3"
            dense
            solo-inverted
            hide-details
            label="Suche in Aufträgen..."
            v-model="search"
          ></v-text-field>

          <v-list color="transparent">
            <v-list-item-group ref="menue_items">
              <!--              <template v-for="auftrag in filterAuftraege">-->
              <template v-for="auftrag in filterAuftraege">
                <v-list-item
                  @click="fetchGutachtenDetails(auftrag.id)"
                  class="auftrag"
                  :key="auftrag.id"
                >
                  <v-row justify="center" no-gutters>
                    <v-col cols="12" align="center">
                      <v-divider style="max-width:60%;margin:0"></v-divider>
                    </v-col>
                    <v-col style="display: flex">
                      <div class="pb-2 pt-2">
                        <v-list-item-title>{{
                          auftrag.client_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle class="pt-1">{{
                          auftrag.partner_name
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="pt-1">
                          {{
                            getTimeDifference(
                              auftrag.create_tst,
                              auftrag.antrag_typ.toLowerCase()
                            )
                          }}
                        </v-list-item-subtitle>
                      </div>
                      <v-spacer class="mr-5"></v-spacer>
                      <v-icon
                        v-if="auftrag.antrag_typ === 'express'"
                        color="red"
                        size="26px"
                        style="font-weight: bold;"
                        >E</v-icon
                      >
                      <v-icon
                        v-else
                        color="green"
                        size="26px"
                        style="font-weight: bold;"
                        >N</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-list-item>
              </template>
            </v-list-item-group>
            <v-divider class="my-2"></v-divider>

            <v-list-item link color="grey lighten-4">
              <v-row justify="center">
                <v-col cols="1" class="ma-0 pa-0 mr-2">
                  <v-icon class="mt-2"> mdi-refresh </v-icon>
                </v-col>
                <v-col cols="auto" class="ma-0 pa-0">
                  <v-list-item-content>
                    <v-list-item-title @click="fetchGutachtenListe()"
                      >Aktualisieren</v-list-item-title
                    >
                  </v-list-item-content>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col class="pt-0">
        <v-container v-if="!this.auftrag_loaded">
          <v-row align="center" justify="center">
            <h1>
              Bitte Gutachten aus der Liste auswählen
            </h1>
          </v-row>
        </v-container>
        <v-stepper v-model="e1" v-show="this.auftrag_loaded">
          <v-stepper-header>
            <template v-for="(value, index) in steps">
              <v-stepper-step
                :key="`${index}-step`"
                :complete="getComplete(index)"
                :step="index + 1"
                :editable="getEditable(index)"
                onload="stepperLoaded"
              >
                {{ value }}
              </v-stepper-step>

              <v-divider v-if="index !== steps" :key="index"></v-divider>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <AuftragDetail
                v-bind:currentStage="stage_name"
                @update-auftrag-state="updateAuftragState"
                @jump-to-next-step="generatePZP"
                @reset-stepper-steps="resetStepperSteps"
              ></AuftragDetail>
            </v-stepper-content>

            <!-- PZP Prüfen -->
            <v-stepper-content step="2">
              <v-card
                v-if="!pzp_pdf"
                class="mb-12"
                color="grey lighten-1"
                height="200px"
              ></v-card>
              <v-card v-else flat>
                <iframe
                  :src="pzp_pdf"
                  class="mb-12"
                  type="application/pdf"
                  width="100%"
                  height="1200"
                />
              </v-card>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    class="ma-2"
                    color="warning"
                    large
                    @click.native.stop="previousStep(2)"
                    >Zurück
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    color="primary"
                    large
                    @click="generateInvoice"
                    >Weiter</v-btn
                  >
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- Rechnung Prüfen -->
            <v-stepper-content step="3">
              <v-card
                v-if="!invoice_pdf"
                class="mb-12"
                color="grey lighten-1"
                height="200px"
              ></v-card>
              <v-card v-else flat>
                <iframe
                  :src="invoice_pdf"
                  class="mb-12"
                  type="application/pdf"
                  width="100%"
                  height="1200"
                />
              </v-card>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    class="ma-2"
                    color="warning"
                    large
                    @click.native.stop="previousStep(3)"
                    >Zurück
                  </v-btn>
                  <v-btn class="ma-2" color="primary" large @click="nextStep(3)"
                    >Weiter</v-btn
                  >
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-container>
                <h2>Vertragsabschluss</h2>
              </v-container>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    class="ma-2"
                    color="warning"
                    large
                    @click.native.stop="previousStep(4)"
                    >Zurück
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    color="primary"
                    large
                    @click="finalizeDeal"
                    >Abschluss</v-btn
                  >
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuftragDetail from "../components/AuftragDetail";
import loader from "../components/Loader";

import { mapGetters } from "vuex";
import dateCalculations from "../services/dateCalculations";
import downloadLinkSigner from "../services/downloadLinkSigner";

const emptyEditableObject = {
  "0": {
    editable: true,
    complete: true
  },
  "1": {
    editable: false,
    complete: false
  },
  "2": {
    editable: false,
    complete: false
  },
  "3": {
    editable: false,
    complete: false
  }
};

export default {
  name: "Uebersicht",
  data: () => ({
    search: "",
    stage_name: "review",
    auftrag_loaded: false,
    e1: 1,
    auftrag_id: undefined,
    invoice_pdf: undefined,
    pzp_pdf: undefined,
    gutachtenListe: [],
    //filteredGutachtenListe: [],
    steps: ["PZP Bearbeiten", "PZP Prüfen", "Rechnung Prüfen", "Abschluss"],
    editable: JSON.parse(JSON.stringify(emptyEditableObject))
  }),
  components: {
    AuftragDetail,
    loader
  },
  watch: {
    // invoice_pdf(newValue, oldValue){
    //   if(oldValue!==undefined) console.log('replacing old value invoice_pdf ...')
    //   this.invoice_pdf=newValue;
    //   alert("NV:"+newValue)
    // },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  // updated: function () {
  //   this.$nextTick(function () {
  //     // Code that will run only after the
  //     // entire view has been re-rendered
  //     alert('updated')
  //   })
  // },
  created() {
    clearInterval(window.oaTrigger);
    console.log("set Interval-Trigger OREV");
    window.oaTrigger = setInterval(async () => {
      await this.fetchGutachtenListe();
    }, 10000);
  },
  methods: {
    customTimeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    stepperLoaded() {
      alert("stepper loaded");
    },
    async finalizeDeal() {
      this.$store.commit("setGutachtenDetailOverlay", true);

      const tmpList = this.getUserPermissions.tabs;
      //get ordernr of an current stage
      let nextStageOrderId = undefined;
      for (let it of tmpList) {
        if (it.step === this.stage_name) {
          nextStageOrderId = (parseInt(it.id) + 1).toString();
          break;
        }
      }
      let nextStageName = undefined;
      for (let it of tmpList) {
        if (it.id.toString() === nextStageOrderId) {
          nextStageName = it.step;
          break;
        }
      }
      await this.$store.dispatch("pushDataToNewStage", {
        id: this.auftrag_id,
        stage: nextStageName,
        finalize: true,
        data: {}
      });
      await this.fetchGutachtenListe();
      this.resetStepper();
      this.$store.commit("setGutachtenDetailOverlay", false);
    },
    async generatePZP() {
      this.$store.commit("setGutachtenDetailOverlay", true);
      console.log("generate pzp");
      this.pzp_pdf = undefined;
      const currentAuftrag = this.$store.getters.getGutachtenDetails;
      const pzpObject = await this.$store.dispatch("generateDealPZP", {
        id: currentAuftrag.metadata.id,
        invoiceId: "wird%20generiert"
      });
      // console.log('pzp Object:'+JSON.stringify(pzpObject));
      const pzpPDFlink = await downloadLinkSigner.getSignedDownloadLink(
        this.$store,
        pzpObject.bucket,
        pzpObject.key
      );
      this.pzp_pdf = pzpPDFlink;
      this.$store.commit("setGutachtenDetailOverlay", false);
      this.nextStep(1);
    },
    async generateInvoice() {
      this.$store.commit("setGutachtenDetailOverlay", true);
      const currentAuftrag = this.$store.getters.getGutachtenDetails;
      const invoiceObject = await this.$store.dispatch(
        "getDealInvoice",
        currentAuftrag.metadata.id
      );
      const invoicePDFlink = await downloadLinkSigner.getSignedDownloadLink(
        this.$store,
        invoiceObject.bucket,
        invoiceObject.key
      );
      this.setInvoicePdfLink(invoicePDFlink);
      this.$store.commit("setGutachtenDetailOverlay", false);
      this.nextStep(2);
    },
    setEdittable(index, value) {
      this.editable[index.toString()].editable = value;
      this.editable[index.toString()].complete = value;
    },
    setInvoicePdfLink(value) {
      //alert('set')
      this.invoice_pdf = value;
    },
    getEditable(index) {
      return this.editable[index.toString()].editable;
    },
    getComplete(index) {
      return this.editable[index.toString()].complete;
    },
    resetStepperSteps() {
      if (this.e1 === 1) {
        console.log("clear stepper");
        this.setEdittable(1, false);
        this.setEdittable(2, false);
        this.setEdittable(3, false);
      }
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
      this.setEdittable(n, true);
      window.scrollTo(0, 0);
    },
    previousStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n - 1;
      }
    },

    async updateAuftragState(auftragWasLoaded) {
      let loadPZP = false;
      if (!this.auftrag_loaded && auftragWasLoaded) loadPZP = true;

      this.auftrag_loaded = auftragWasLoaded;
      if (loadPZP) {
        //alert('gofurther');
        //await this.customTimeout(3000);
        await this.generatePZP();
      }
    },
    getTimeDifference(auftragTimestamp, antragsTyp) {
      return dateCalculations.getTimeDifference(
        auftragTimestamp,
        antragsTyp,
        this.$store
      )[0];
    },
    async fetchGutachtenListe() {
      console.log("event trigger - fetch list OREV");
      this.gutachtenListe = await this.$store.dispatch(
        "fetchGutachtenListe",
        this.stage_name
      );
    },
    async fetchGutachtenDetails(id) {
      console.log("fetchGutachtenDetails loading..");
      this.resetStepper();
      this.auftrag_id = id;
      this.$store.commit("setGutachtenDetailOverlay", true);
      await this.$store.dispatch("fetchGutachtenDetails", id);

      //ausnahme weil wir automatisch zu step zwei umschalten
      //this.$store.commit("setGutachtenDetailOverlay", false);
    },
    resetStepper() {
      this.auftrag_loaded = false;
      this.auftrag_id = undefined;
      this.e1 = 1;
      this.invoice_pdf = undefined;
      this.editable = JSON.parse(JSON.stringify(emptyEditableObject));
    }
  },

  computed: {
    ...mapGetters([
      "getGutachtenListe",
      "getGutachtenDetails",
      "getUserPermissions"
    ]),
    filterAuftraege() {
      const filtered = this.gutachtenListe;
      return filtered
        .sort((a, b) => {
          return Number(
            dateCalculations.getTimeDifference(
              a.create_tst,
              a.antrag_typ.toLowerCase(),
              this.$store
            )[1]
          ) >
            Number(
              dateCalculations.getTimeDifference(
                b.create_tst,
                b.antrag_typ.toLowerCase(),
                this.$store
              )[1]
            )
            ? 1
            : -1;
        })
        .filter(item => {
          if (!this.search) return this.gutachtenListe;
          return (
            item.partner_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.client_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.kennzeichen
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.schadennummer
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.vorgangsnummer
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
    }
  },
  async mounted() {
    await this.fetchGutachtenListe();
    this.$store.commit("setMainContextName", "Übersicht - Freigabe");
    //this.$refs.menue_items.$children[0].$el.click()
  }
};
</script>

<style>
.auftragItem {
  cursor: pointer;
}
.auftragItem:hover {
  background-color: gray;
}
</style>
